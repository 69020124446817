<template>
  <div>
    <!--<el-form :inline="true">-->
      <!--信息审核 搜索区域 -->
    <!--  <el-form-item>-->
    <!--    <el-input-->
    <!--      v-model="queryinfo.query"-->
    <!--      :clearable="true"-->
    <!--      @clear="getlist"-->
    <!--      style="width:200px"-->
    <!--    >-->
    <!--      <el-button-->
    <!--        slot="append"-->
    <!--        icon="el-icon-search"-->
    <!--        @click="getlist"-->
    <!--      ></el-button>-->
    <!--    </el-input>-->
    <!--  </el-form-item>-->
    <!--  <el-form-item>-->
    <!--    <el-date-picker-->
    <!--      v-model="queryinfo.start"-->
    <!--      type="datetime"-->
    <!--      :placeholder="$t('qxz')"-->
    <!--      align="right"-->
    <!--      :picker-options="pickerOptions"-->
    <!--      value-format="timestamp"-->
    <!--    >-->
    <!--    </el-date-picker>-->
    <!--  </el-form-item>-->
    <!--  <el-form-item>-->
    <!--    <el-date-picker-->
    <!--      v-model="queryinfo.end"-->
    <!--      type="datetime"-->
    <!--      :placeholder="$t('qxz')"-->
    <!--      align="right"-->
    <!--      :picker-options="pickerOptions"-->
    <!--      value-format="timestamp"-->
    <!--    >-->
    <!--    </el-date-picker>-->
    <!--  </el-form-item>-->
    <!--  <el-button icon="el-icon-search" circle @click="getlist"></el-button>-->
    <!--</el-form>-->
    <!-- 列表区域 -->
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="nickname" label="昵称"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <!--<el-table-column prop="trade" :label="$t('mmb.ycjsl')"></el-table-column>-->
      <!--<el-table-column-->
      <!--  prop="trade_type"-->
      <!--  :label="$t('mmb.leixing')"-->
      <!--></el-table-column>-->
      <!--<el-table-column-->
      <!--  prop="product_type"-->
      <!--  :label="$t('mmb.cplx')"-->
      <!--></el-table-column>-->
      <el-table-column prop="price" :label="$t('mmb.jydj')"></el-table-column>
      <!--<el-table-column-->
      <!--  prop="number_max"-->
      <!--  :label="$t('mmb.zdjysl')"-->
      <!--></el-table-column>-->
      <!--<el-table-column-->
      <!--  prop="number_min"-->
      <!--  :label="$t('mmb.zxjysl')"-->
      <!--></el-table-column>-->
      <!--<el-table-column-->
      <!--  prop="number_total"-->
      <!--  :label="$t('mmb.jyzl')"-->
      <!--></el-table-column>-->
      <!--<el-table-column prop="status" :label="$t('zhuangtai')">-->
      <!--  <template slot-scope="{ row }">-->
      <!--    <span v-if="row.status === '0'">{{ $t('mmb.dsh') }}</span>-->
      <!--    <span v-else-if="row.status === '1'">{{ $t('mmb.ysh') }}</span>-->
      <!--    <span v-else-if="row.status === '2'">{{ $t('mmb.jujue') }}</span>-->
      <!--    <span v-else-if="row.status === '3'">{{ $t('mmb.yjs') }}</span>-->
      <!--  </template>-->
      <!--</el-table-column>-->
      <el-table-column
        prop="createtime"
        :label="$t('cjsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column prop="remark" :label="$t('beizhu')"></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }" >
          <i class="el-icon-chat-dot-square" style="font-size: 30px" @click="edit(row.id,row.user_id)"></i>
          <span class="tips" v-if="row.chat_count > 0">{{row.chat_count}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 消息列表 -->
    <el-dialog
      title="消息列表"
      center
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
    >
      <div class="maincontent">
        <div v-if="list.length > 0">
          <div
            class="chat"
            v-for="item in list"
            :key="item.id"
            @click="tochat(item.orderid)"
          >
            <div>
              <div span="12" class="name"
                >{{ item.remark === '' ? item.username : item.remark
                }}<span class="dot" v-if="+item.count">{{
                  item.count
                }}</span>
                <span span="12" class="editname" style="float: right;" @click.stop="editName(item.user_id)">
                  <i class="el-icon-edit"></i>
                </span>
              </div>
              <div span="12" class="time">{{
                item.lastmsg.createtime | dateformat
              }}</div>
            </div>
            <div class="van-ellipsis info">
              <span v-if="item.lastmsg.type === 'text'">
                {{ item.lastmsg.content }}
              </span>
              <i name="photo-o" v-else style="font-size:1rem" />
            </div>
            <div class="van-hairline--bottom"></div>
          </div>
        </div>
        <div  v-else>暂无消息</div>
      </div>
    </el-dialog>
    <el-dialog :title="remark === '' ? chatinfoName : remark" :visible.sync="dialogTableVisible" @close="xiaoxiClose" :lock-scroll="true">
        <div class="maincontent1">
       <!--订单信息 -->
          <div class="orderinfo">
            <div class="info-top">
              <div span="12" style="text-align:left">交易总额</div>
              <div span="12" style="text-align:right">
                <!--<span v-if="parseInt(orderinfo.times) > 0">-->
                <!--  <i name="clock"/><i-->
                <!--    :time="parseInt(orderinfo.times * 1000)"-->
                <!--    format="mm:ss"-->
                <!--/></span>-->
              </div>
            </div>
            <div class="info-bottom"
              ><div span="12" style="text-align:left">￥<span>{{trade_num * price}}</span></div>
              <div span="12" style="text-align:right">
                <span v-if="orderinfo.status === '-1'">已取消</span>
                <span v-if="orderinfo.status === '0'">待付款</span>
                <span v-if="orderinfo.status === '1'">已付款</span>
                <span v-if="orderinfo.status === '2'">已完成</span>
              </div>
            </div>
          </div>
           <!--聊天记录 -->
          <div class="chatlist">
            <span v-show="isLoading" style="color:#2C90FF">加载中...</span>
            <div v-for="(item,index) in listLi" :key="index">
              <div v-if="item.type === 'order'" class="chat-order">
                <span class="order-time">{{ item.createtime | dateformat }}</span>
                <span class="order-msg">{{ item.content }}</span>
              </div>
              <div
                v-else
                class="chatinfo"
                :class="[item.userid === userid ? 'right' : 'left' , item.withdraw === '1' ? 'withdrawCenter' : '' ]"
              >
                <div
                  v-if="item.userid === userid"
                  style="display:flex;justify-content:flex-end"
                >
                  <!--<span class="" v-if="item.withdraw === '1' && item.userid === userid">{{ item.createtime | dateformat }}</span>-->
                  <span class="" v-if="item.withdraw === '1' && item.userid === userid" style="display: inline-block">{{ item.createtime | dateformat }}<br>您  撤回了一条消息</span>
                  <span v-else style="display:flex">
                    <span style="font-size: 12px;padding: 0.4rem 0rem;text-align: right">{{item.createtime | dateformat}}<br>{{ item.isread === '1' ? '已读' : '未读' }}</span>
                    <span  v-if="item.type === 'image'">
                      <img
                         style="width:200px;padding: 0.4rem"
                        :src="item.content"
                        @click="open(item.id)"
                      />
                    </span>
                    <span class="chatcontent" v-else v-html="item.content" @click="open(item.id)"></span>
                  </span>
                  <div
                    style="width:32px;height:32px;background:#004ea3;color:#fff;border-radius:50%;line-height:32px;font-weight:600;font-size:1rem;text-align:center"
                  >
                    {{ username.substr(0, 1) }}
                  </div>
                </div>
                <div v-else style="display:flex;justify-content: flex-start;" :class="[item.withdraw === '1' ? 'imgHide' : '']">
                  <div
                    style="width:32px;height:32px;background:#000;color:#fff;border-radius:50%;line-height:32px;font-weight:600;font-size:1rem;text-align:center"
                  >
                    {{ tousername.substr(0, 1) }}
                  </div>
                  <span class="" v-if="item.withdraw === '1'">对方  撤回了一条消息</span>
                  <span v-else style="display:flex">
                    <span v-if="item.type === 'image'">
                      <img
                        style="width:200px;padding: 0.4rem"
                        :src="item.content"
                      />
                    </span>
                    <span class="chatcontent" v-else v-html="item.content"></span>

                    <span style="font-size: 12px;padding: 0.4rem 0rem;">{{item.createtime | dateformat}}<br>{{ item.isread === '1' ? '已读' : '未读' }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sendbox">
          <div class="van-hairline--top"></div>
          <el-input
            v-model="message"
            rows="1"
            autosize
            type="textarea"
            placeholder="在此输入你的对话"
            class="van-field__control"
            @keyup.enter.native="sendtext"
          />
          <el-upload
              class="upload-demo"
              :headers="headers"
              :action="weburl + '/admin/user/uploadimg'"
              multiple
              :on-success="afterRead"
              >
              <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <el-button class="send-btn" size="small" type="primary" @click="sendtext"
            >发 送</el-button
          >
        </div>
    </el-dialog>
    <el-dialog
      title="修改备注"
      center
      :visible.sync="changeName"
      :show-close="true"
    >
      <div style="display:flex">
        <el-input v-model="beizhu" placeholder="请输入修改备注"></el-input>
        <el-button size="small" type="primary" @click="hangleClickChangeName" round>确认修改</el-button>
      </div>
    </el-dialog>
    <audio controls ref="notify" style="display:none">
      <source src="msg.mp3" />
    </audio>
    <!--<button v-longpress="incrementPlusTen">按钮</button>-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 聊天消息
      list: [],
      // 快捷时间
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 分页请求数据对象
      queryinfo: {
        query: '',
        start: '',
        end: '',
        page: 1
      },
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      orderId: '',
      infolist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      editid: '',
      // 统计信息
      order_count: 0,
      total_money: 0,
      profit: 0,
      shenhevisible: false,
      innerVisible: false,
      // 聊天记录备注名字
      remark: '',
      // 聊天记录名字
      chatinfoName: '',
      editform: {
        payment: {
          name: '',
          phone: ''
        }
      },
      scrollHeight: 0,
      orderinfo: {},
      userid: '',
      touid: '',
      tousername: '',
      username: '',
      websocket: null,
      message: '',
      isLoading: true,
      listLi: [],
      page: 1,
      count: 0,
      status_timer: null,
      dialogTableVisible: false,
      orderID: '',
      trade_num: '',
      price: '',
      changeName: false,
      // 用户备注修改内容
      beizhu: '',
      // 用户备注修改ID
      beizhuId: '',
      // 撤回消息 id
      bindingId: '',
      // 消息列表
      chatinfoList: '',
      // 消息ID
      messageID: '',
      scrollHeight: ""
    }
  },
  created() {
    this.getlist()
    this.$alert('消息管理页面', '提示', {
      confirmButtonText: '确定'
    })
    // this.getinfo()
  },
  mounted() {
    setInterval(() => {
      this.getcountall()
    }, 10000)


  },
  methods: {
    // increment value plus 10
    incrementPlusTen(item) {
      console.log(item)
      // this.open()
    },
    async open(id) {
      console.log(id)
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        const { data } = await this.$http.get(`/admin/user/withdraw?id=${id}`)
        if (data) {
          if (data.code === 200) {
            this.$message({
              type: 'success',
              message: '撤回成功!'
            })
            this.websocketsend(
              JSON.stringify({
                touid: this.touid,
                type: 'withdraw',
                id
              })
            )
            this.listLi.forEach(item => {
              if (item.id === id) {
                item.withdraw = '1'
              }
            })
          } else if (data.code === 400) {
            this.$message({
              type: 'info',
              message: '撤回失败,只允许撤回十分钟之内的消息'
            })
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤回'
        })
      })
    },
    async getcountall() {
      const { data } = await this.$http.get('/admin/user/getcountall')
      if (data) {
        if (data.code === 200) {
          if (data.data.count > 0) {
            this.$refs.notify.play()
            this.getlist()
          }
        }
      }
      console.log(data)
    },
    // 打开聊天页面
    tochat(orderid) {

      this.count = 0
      this.chatinfoName = ""
      this.remark = ""
      this.chatinfoList = []
      this.listLi = []
      this.page = 1
      // this.$router.push('/user/Contactt/' + orderid)
      this.editdialogvisible = false
      this.orderID = orderid
      this.dialogTableVisible = true
      this.getlist1()
      this.getorderinfo()
      this.getorderstatus()
      // this.isread()
      if (this.websocket == null) {
        this.initwebsocket()
      }

    //   console.log(container)
    //   console.log(container)
        setTimeout(() => {
          const container = document.querySelector('.chatlist')
          this.scrollHeight = container.scrollHeight
          container.scrollTo(0, this.scrollHeight)

          container.addEventListener('scroll', (e) => {
              if (
                e.target.scrollTop === 0 &&
                this.page <= 20 &&
                this.count > this.page * 20
              ) {
                    this.isLoading = true
                setTimeout(() => {
                  this.page++

                  this.getlist1()
                //   setTimeout(() => {
                //     container.scrollTo(0, e.target.scrollHeight - this.scrollHeight)
                //   }, 20)
                }, 1000)
                this.scrollHeight = container.scrollHeight
              }
            })
        }, 800)

    },
    // 修改备注
    editName(orderid) {
      this.beizhuId = orderid
      this.changeName = true
    },
    // 确认修改
    async hangleClickChangeName() {
      const { data } = await this.$http.post('/admin/user/edit_remarks', {
        id: this.beizhuId,
        remark: this.beizhu
      })
      if (data) {
        if (data.code === 200) {
          this.changeName = false
          this.beizhu = ''
          this.edit(this.editid, this.userid)
        }
      }
    },
    // 获取商户列表
    async getlist() {
      const { data } = await this.$http.get('/admin/user/review', {
        params: this.queryinfo
      })
      // let arr = data.data.list
      // arr = arr.filter(item =>{
      //   return item.chat_count > 0
      // })
      // this.infolist = arr
      this.infolist = data.data.list
      this.total = +data.data.count
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 消息列表
    async edit(id, userId) {
      this.list = ''
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      this.editdialogvisible = true
      this.editid = id
      const { data } = await this.$http.get(`/admin/user/chatlist?uid=${userId}`)
      loading.close()
      console.log(data)
      if (data) {
        if (data.code === 200) {
          console.log(data.data.list)
          data.data.list.sort(function(a, b) {
            return b.count.localeCompare(a.count)
          })
          this.list = data.data.list
          this.userid = userId
        }
      }
    //   loading.close()
    },
    // 关闭编辑弹窗
    editclose() {
      this.editdialogvisible = false
      this.getlist()
    },
    // 获取订单信息
    async getorderinfo() {
      const { data } = await this.$http.get(
        '/admin/user/orderinfo?id=' + this.orderID
      )
      if (data) {
        if (data.code === 200) {
          this.orderinfo = data.data.orderinfo
          this.userid = data.data.userid
          this.touid = data.data.touid
          this.username = data.data.username
          this.tousername = data.data.tousername
          this.trade_num = this.orderinfo.trade_num
          this.price = this.orderinfo.price
          if (this.websocket === null) {
            // 请求数据后再初始化ws
            this.initwebsocket()
          }
        }
      }
    },
    // 获取订单状态
    async getorderstatus() {
      const { data } = await this.$http.get(
        '/admin/user/orderstatus?id=' + this.orderID
      )
      if (data) {
        if (data.code === 200) {
          if (this.orderinfo.status !== data.data) {
            this.orderinfo.status = data.data
            // this.list.push({
            //   userid: this.userid,
            //   type: 'order',
            //   content: '买家已标记付款完成。请及时收款。',
            //   createtime: Math.round(new Date().getTime() / 1000).toString()
            // })
          }
        }
      }
    },
    // 订单详情页
    todetail() {
      // console.log(this.orderinfo.id)
      if (this.orderinfo.type === '卖家') {
        this.$router.push(
          '/trade/shopping/trading/buy_detail/' + this.orderID
        )
      } else {
        this.$router.push(
          '/trade/shopping/trading/sell_detail/' + this.orderID
        )
      }
    },
    // 获取历史记录
    async getlist1() {
      const { data } = await this.$http.get(
        '/admin/user/chatinfo?id=' + this.orderID + '&page=' + this.page
      )
      if (data) {
        if (data.code === 200) {
          this.count = +data.data.count
          this.chatinfoName = data.data.username
          this.remark = data.data.remark
          this.chatinfoList = data.data.list
          this.chatinfoList.forEach(item => {
            this.listLi.unshift(item)
            // this.isread()
          })
        }
      }
      this.isLoading = false
    //   this.goto()
    },
    // 消息详情界面关闭
    xiaoxiClose() {
      console.log('关闭聊天')
      this.listLi = []
      this.getlist()
      this.websocketsend(JSON.stringify({
        type: 'close'
      }))
      this.websocket = null
    },
    // 滚动到底部
    goto() {
      this.$nextTick(() => {
        const dom = document.querySelector('.chatlist')
        dom.scrollTop += dom.scrollHeight
      })
    },
    // 初始化连接
    initwebsocket() {
      this.websocket = new WebSocket('wss://wp.36vk.com' + '/ws')
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onerror = this.websocketonerror
    },
    // 绑定uid
    binduid() {
      const msg = {
        type: 'bind',
        userid: this.userid,
        orderid: this.orderinfo.id
      }
      this.websocketsend(JSON.stringify(msg))
    },
    // 连接错误
    websocketonerror() {
      this.initwebsocket()
    },
    // 接收到数据
    websocketonmessage(e) {
      const message = JSON.parse(e.data)
      switch (message.type) {
        case 'init':
          this.binduid()
          break
        case 'order':
          this.listLi.push(message)
          break
        case 'text':
          message.withdraw = 0
          message.isread = '1'
          this.listLi.push(message)
          this.$refs.notify.play()
          this.isread()
          this.goto()
          // this.getcountall()
          break
        case 'image':
          if (message.userid === this.touid) {
            message.withdraw = 0
            message.isread = '1'
            this.listLi.push(message)
            this.$refs.notify.play()
            this.isread()
            this.goto()
          }
          break
        case 'getid':
          this.listLi[this.listLi.length - 1].id = message.id
          break
        case 'withdraw':
          this.listLi.forEach((item) => {
            if (item.id === message.id + '') {
              item.withdraw = '1'
            }
          })
          break
      }
    },
    // websorcket发送信息
    websocketsend(data) {
      this.websocket.send(data)
    },
    // 发送信息
    sendmsg(type) {
      if (this.message === '') {
        return false
      }
      const msgdata = {
        type: type,
        userid: this.userid,
        touid: this.touid,
        content: this.message,
        orderid: this.orderinfo.id,
        isread: '0',
        createtime: new Date().getTime() / 1000,
        withdraw: 0
      }
      this.websocketsend(JSON.stringify(msgdata))
      msgdata.content = msgdata.content.replace('\n', '<br/>')
      this.listLi.push(msgdata)
      this.message = ''
      this.goto()
    },
    // 发送文本信息
    sendtext() {
      this.sendmsg('text')
    },
    // 图片上传
    async afterRead(res) {
      this.message = res.data.url
      this.sendmsg('image')
    },
    // 图片预览
    // previewimg(url) {
    //   ImagePreview([url])
    // },
    // 更新消息为已读
    async isread() {
      await this.$http.get('/home/user/isread?id=' + this.$route.params.id)
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.tongji {
  width: 100%;
  height: 56px;
  margin: 20px 0;
  .touzi {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    margin-right: 20px;
    overflow: hidden;
    span:first-child {
      background: #2389f2;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
  .dingdan {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    overflow: hidden;
    margin-right: 20px;
    span:first-child {
      background: #ffc913;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
  .yingkui {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    overflow: hidden;
    span:first-child {
      background: #ff6213;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
}
.maincontent {
  /*padding: 60px 0;*/
  display: block;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 50vh;
  .chat {
    padding: 12px 1rem;
    border-bottom: 1px solid #ddd7d7;
    .name {
      color: #171717;
      text-align: left;
      font-size: 1rem;
      position: relative;
      .dot {
        position: absolute;
        background: #f00;
        width: 14px;
        height: 14px;
        display: inline-block;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 14px;
        font-size: 12px;
        top: 0;
      }
    }
    .time {
      font-size: 0.85rem;
      text-align: right;
      color: #999;
    }
    .info {
      text-align: left;
      font-size: 0.85rem;
      color: #999;
      margin: 8px 0 12px 0;
    }
  }
}
.maincontent1 {
  /*padding: 46px 0;*/
  display: flex;
  flex-direction: column;
  font-size: 0.93333rem;
  background: #f1f3f5;
  height: 60vh;
  /*// 订单信息*/
  .orderinfo {
    background: #fff;
    padding: 10px 0;
    /*position: fixed;*/
    width: 100%;
    z-index: 999;
    .info-top {
      font-size: 0.725rem;
      color: #999;
      padding: 0 20px;
      margin-bottom: 8px;
      .van-icon {
        color: #ccc;
        margin-right: 5px;
      }
      .van-count-down {
        display: inline-block;
        color: #999;
      }
    }
    .info-bottom {
      font-size: 1rem;
      color: #004ea3;
      padding: 0 18px;
    }
  }
  .chatlist {
    /*margin-top: 6rem;*/
     overflow-y: auto;
     /*height: 77%;*/
  }
  .chat-order {
    font-size: 0.8rem;
    margin: 1rem 0;
    text-align: center;
    .order-time {
      display: block;
      color: #ccc;
      line-height: 1.5rem;
      font-size: 13px;
    }
    .order-msg {
      text-align: center;
      display: inline-block;
      padding: 0.3rem 0.6rem;
      background: #ccc;
      color: #fff;
    }
  }
  /*// 聊天信息*/
  .chatinfo {
    display: flex;
    margin: 0.5rem;
    flex: 1;
    .chatcontent {
      border-style: solid;
      border-width: 1px;
      border-radius: 0.2rem;
      color: #333;
      font-size: 0.9rem;
      margin: 0.3rem 0.65rem;
      padding: 0.4rem 0.5rem;
      max-width: 75%;
      position: relative;
      text-align: left;
      word-break: break-all;
    }
  }
  .left {
    justify-content: flex-start;
    .chatcontent {
      background-color: #fff;
      border-color: #fff;
      &:before {
        content: '';
        display: inline-block;
        height: 0;
        width: 0;
        position: absolute;
        border-top: 0.3rem solid transparent;
        border-right: 0.5rem solid #fff;
        border-bottom: 0.5rem solid transparent;
        top: 1rem;
        left: -0.5rem;
      }
    }
  }
  .right {
    justify-content: flex-end;
    .chatcontent {
      background-color: #9eea6a;
      border-color: #9eea6a;
      &:before {
        content: '';
        display: inline-block;
        height: 0;
        width: 0;
        position: absolute;
        border-top: 0.3rem solid transparent;
        border-left: 0.5rem solid#9EEA6A;
        border-bottom: 0.5rem solid transparent;
        top: 1rem;
        right: -0.5rem;
      }
    }
  }
}
.sendbox {
  border-top: 1px solid #ebebeb;
  background: #f8f8f8;
  padding: 0.5rem;
  display: flex;
  align-items: flex-end;
  /*width: 100%;*/
  /*position: fixed;*/
  bottom: 0;
  left: 0;
  .van-cell {
    margin: 0;
    padding: 0;
    border-radius: 3px;
  }
  .img-btn {
    font-size: 1.6rem;
    color: #999;
    padding: 3px 8px;
  }
  .send-btn {
    width: 5rem;
    padding: 0;
    height: 1.84rem;
  }
}
.van-field__control {
  min-height: 2rem;
  display: inline-block;
  background: #fff;
  padding: .1rem .2rem;
  max-height: 5rem;
  overflow-y: scroll;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #323233;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  resize: none;
}
.avatar-uploader-icon{
  font-size: 28px;
}
.tips{
  position: absolute;
  background: #f00;
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 14px;
  font-size: 12px;
  top: 10px;
}
.withdrawCenter{
  justify-content: center !important;
  padding: 0.3rem 0.6rem !important;
  font-size: 0.8rem;
  span{
      text-align: center;
      display: inline-block;
      padding: 0.3rem 0.6rem;
      background: #ccc;
      color: #fff;


  }
   div{
    display: none;
  }
}
.imgHide{

}
</style>
